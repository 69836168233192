import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({
  stakeData,
}) => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Stake <span>BDNX</span> to enjoy <span>11% APY</span> </h2>
          <div className={styles.text}>
            Stake to enjoy zero-fee withdrawals
          </div>
          <div className={styles.firstSection}>
            <div className={styles.secondSection}>
              <div className={styles.firstColumn}>
                <div className={styles.odd}>Staked amount</div>
                <div className={styles.even}>APY(Annual Percentage Yield)</div>
                <div className={styles.odd}>Tier</div>
                <div className={styles.even}>Early Redeem Penalty(%)</div>
              </div>
              {stakeData?.map((data, index) => (
                <div key={index} className={styles.secondColumn}>
                  <div className={styles.secondFirstChild}>
                    {parseInt(data?.stakeAmount).toLocaleString()} <small>BDNX</small>
                  </div>
                  <div className={styles.secondSecondChild}>{data?.annualPercentage}</div>
                  <div className={styles.secondThirdChild}>{data?.tier}</div>
                  <div className={styles.secondSecondChild}>{data?.earlyRedeemPlenty}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bg}>
        <img
          srcSet="/images/content/legal/legal-main@2x.png 2x"
          src="/images/content/bdnxTokenStaking.png"
          alt="Cards"
        />
      </div>
    </div>
  );
};

export default Main;
