import React, { useEffect, useState } from "react";
import Main from "./Main";
import GetToken from "./GetToken";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";

const BitdenexToken = () => {
  const [loading, setLoading] = useState(false);
  const [stakeData, setStakeData] = useState([]);

  useEffect(() => {
    getStakeValuesHandler();
  }, []);

  const getStakeValuesHandler = async () => {
    setLoading(true);
    try {
      const stakePayload = await requestHandler("get_open_stake_values", "post");
      setStakeData(stakePayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <Main
        stakeData={stakeData}
      />
      <GetToken />
    </>
  );
};

export default BitdenexToken;
