import React, { useEffect, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import requestHandler from "../../actions/httpClient";
import { socket } from "../../socket";

const Market = () => {

  const [allMarketsData, setAllMarketsData] = useState([]);
  const [dataMarkets, setDataMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(1);

  useEffect(() => {
    socket.connect();

    socket.on("all_markets_web", (data) => {
      setAllMarketsData(data);
      setLoading(false);

      if (activeIndex === 1) {
        // const sortedMarkets = data[0]?.markets?.sort((a, b) => a?.market_position - b?.market_position);
        const sortedMarkets = data[0]?.markets;
        setDataMarkets(sortedMarkets);
      } else {
        // const sortedMarkets = data?.find(x => x?.currencyId === activeIndex)?.markets?.sort((a, b) => a?.market_position - b?.market_position);
        const sortedMarkets = data?.find(x => x?.currencyId === activeIndex)?.markets;
        setDataMarkets(sortedMarkets);
      }
      setLoading(false);
    });

    return () => {
      socket.off("all_markets_web");
      socket.disconnect();
    };
  }, [activeIndex]);

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      setLoading(false);
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == 'eur') {
          getAllMarkets(regionPayload?.data?.data[i]?.id);
        }
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getAllMarkets = async (id) => {
    try {
      setLoading(true)
      let data = {
        type: "allMarkets",
        region: id,
      };
      // const marketsPayload = await requestHandler("get_markets", "post", data);
      // const sortedMarkets = marketsPayload?.data?.data[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
      // setAllMarketsData(marketsPayload?.data?.data);
      // setDataMarkets(sortedMarkets);
      // setLoading(false)
    }
    catch (e) {
      setLoading(false)
    };
  };

  return (
    <>
      <Main />
      <Details
        allMarketsData={allMarketsData}
        loading={loading}
        dataMarkets={dataMarkets}
        setDataMarkets={setDataMarkets}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </>
  );
};

export default Market;
