import { getChartData, getChartDataWithoutTime } from "./demoTradingChart";

const history = {};
let previousBase = null;
let previousURL = null;

export default {
  history: history,
  hitted: false,

  async getBars(symbolInfo, resolution, endTime, first) {
    const base = process.env.REACT_APP_API_URL_CHARTS;
    const currentURL = window.location.href;

    if (base !== previousBase || currentURL !== previousURL) {
      // Reset the 'hitted' flag if base or URL has changed
      this.hitted = false;
      previousBase = base;
      previousURL = currentURL;
    }

    try {
      let data;
      if (!this.hitted) {
        this.hitted = true;
        data = await getChartData(symbolInfo.name, resolution, endTime, base);
      } else {
        data = await getChartDataWithoutTime(symbolInfo.name, resolution, endTime, base);
        if (!data.data.data.length) {
          this.hitted = false;
          return [];
        }
      }

      if (data?.data?.data?.length) {
        const myBars = data.data.data;
        const bars = myBars.map((el) => ({
          time: el.time * 1000,
          low: parseFloat(el.low),
          high: parseFloat(el.high),
          open: parseFloat(el.open),
          close: parseFloat(el.close),
          volume: parseFloat(el.volume),
        }));

        if (first) {
          const lastBar = bars[bars?.length - 1];
          history[symbolInfo.name] = { lastBar };
        }
        return bars;
      }

      // Reset hitted if no data was found
      this.hitted = false;
      return [];
    } catch (error) {
      // Handle error, log if necessary
      return [];
    }
  },
};
