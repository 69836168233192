import React, { useState } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Panel from "./Panel";
import Trade from "./Trade";
// import Notice from "./Notice";

const Details = ({
  allMarketsData,
  loading,
  dataMarkets,
  setDataMarkets,
  activeIndex,
  setActiveIndex,
  handleButtonClick,
}) => {

  return (
    <div className={cn("section", styles.details)}>
      <div className={cn("container", styles.container)}>
        <Panel
          allMarketsData={allMarketsData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          loading={loading}
          setDataMarkets={setDataMarkets}
        />
        <Trade
          loading={loading}
          dataMarkets={dataMarkets}
          setDataMarkets={setDataMarkets}
        />
        {/* <Notice /> */}
      </div>
    </div>
  );
};

export default Details;
