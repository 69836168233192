import axios from "axios";

export const getChartData = (
    symbol,
    resolution,
    endTime,
    base,
) => {
    return axios.get(`${base}/history?symbol=${symbol}&resolution=${resolution}&endTime=${endTime}`);
};

export const getChartDataWithoutTime = (
    symbol,
    resolution,
    endTime,
    base,
) => {
    return axios.get(`${base}/history?symbol=${symbol}&resolution=${resolution}&endTime=${endTime}`);
};
