import historyProvider from "./historyProvider";

var _subs = [];

export default {
  subscribeBars: function (
    symbolInfo,
    resolution,
    updateCb,
    uid,
    resetCache
  ) {
    const channelString = createChannelString(symbolInfo);
    const newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
      listener: updateCb,
    };
    _subs.push(newSub);
  },
  unsubscribeBars(uid) {
    const subIndex = _subs.findIndex((e) => e.uid === uid);
    if (subIndex === -1) {
      return;
    }
    const sub = _subs[subIndex];
    _subs.splice(subIndex, 1);
  },
};

export function updateChart(e) {
  const data = {
    ts: e.ts,
    price: e.price,
    total: e.total,
    base_coin_id: e.base_coin_id,
    trade_coin_id: e.trade_coin_id,
  };

  const sub = _subs[0];
  if (sub) {
    const _lastBar = updateBar(data, sub);
    sub.listener(_lastBar);
    sub.lastBar = _lastBar;
  }
}

function updateBar(data, sub) {
  const lastBar = sub.lastBar;
  let resolution = sub.resolution;
  if (resolution.includes("D")) {
    resolution = 1440;
  }

  const coeff = resolution * 60;
  const rounded = Math.floor(data.ts / coeff) * coeff;
  const lastBarSec = lastBar.time / 1000;
  let _lastBar;
  if (rounded > lastBarSec) {
    _lastBar = {
      time: rounded * 1000,
      open: lastBar.close,
      high: lastBar.close,
      low: lastBar.close,
      close: data.price,
      volume: parseFloat(data.total),
    };

    if (data.price < _lastBar.low) {
      _lastBar.low = data.price;
    } else if (data.price > _lastBar.high) {
      _lastBar.high = data.price;
    }
    lastBar.volume = parseFloat(lastBar.volume) + parseFloat(data.total);
    _lastBar.close = data.price;
  } else {
    if (data.price < lastBar.low) {
      lastBar.low = data.price;
    } else if (data.price > lastBar.high) {
      lastBar.high = data.price;
    }

    lastBar.volume = parseFloat(lastBar.volume) + parseFloat(data.total);
    lastBar.close = data.price;
    _lastBar = lastBar;
  }
  return _lastBar;
}

function createChannelString(symbolInfo) {
  const channel = symbolInfo.name.split(":")[1].split("/");
  const exchange = channel[0];
  const to = channel[1];
  const from = channel[0];
  return `${from}~${to}`;
}

export function last(array) {
  return array[array.length - 1];
}
