import React from "react";
import cn from "classnames";
import styles from "./Releases.module.sass";
import { Link } from "react-router-dom";

const Releases = ({ scrollToRef }) => {

  return (
    <div
      className={cn("section-mb0", styles.releases)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <img src="/images/content/walletStaking.png" />
        <div className={styles.text}>
          Login to view profits.
        </div>
        <Link className={cn("button")} to={"/sign-in"}>
          Log in
        </Link>
      </div>
    </div>
  );
};

export default Releases;
