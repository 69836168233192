import React, { useState } from "react";
// import cn from "classnames";
// import styles from "./ForgotPassword.module.sass";
import Login from "../../components/Login";
import Form from "./Form";
import Verification from "./Verification";
import NewPassword from "./NewPassword";
import { useLocation, useNavigate } from "react-router";
import { addNotification } from "../../components/Notification";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import Logo from "../../components/Logo";

const ForgotPassword = () => {
  const state = useLocation();
  // initializing the states and variables 
  const [acitveIndex, setActiveIndex] = useState(state?.state?.code ? state?.state?.code : 0);
  // const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  // const [otp, setOtp] = useState();
  const navigate = useNavigate();
  const [type, setType] = useState("");
  // API's data
  let resetPasswordLoading = false
  let resetPasswordOtpLoading = false

  // function to call the reset password otp generator API
  const resetPasswordOtpGenerateHandler = async (email) => {
    setEmail(email);
    setLoading(true)
    try {
      resetPasswordOtpLoading = true
      let formData = {
        email,
        type: state?.state?.type ? state?.state?.type : "reset_password",
        role: "customer",
      };
      const resetPasswordOtpGeneratePayload = await requestHandler("gen_otp", "post", formData);
      setType(resetPasswordOtpGeneratePayload?.data?.message?.type);
      if (resetPasswordOtpGeneratePayload && resetPasswordOtpGeneratePayload.status === 201) {
        setLoading(false)
        setActiveIndex(1);
      }
    }
    catch (e) {
      setLoading(false)
      resetPasswordOtpLoading = false
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    };
  };

  const varifyOtp = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField, email) => {
    setLoading(true)
    try {
      let formData = {
        email_otp: firstField + secondField + thirdField + fourthField + fifthField + sixthField,
        type: state?.state?.type ? state?.state?.type : type,
        email: state?.state?.email ? state?.state?.email : email,
      };
      const VerifyOtpPayload = await requestHandler("verify_otp", "post", formData);
      if (VerifyOtpPayload.status === 200) {
        setLoading(false)
        setActiveIndex(2)
      }
      else {
        addNotification({
          title: 'Error',
          message: VerifyOtpPayload?.data?.message[0]?.msg,
          type: 'danger'
        });
      }
    }
    catch (e) {
      setLoading(false)
      resetPasswordLoading = false
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };

  }

  // function to call the reset password API
  const resetPasswordHandler = async (email, password, confirmPassword) => {
    setLoading(true)
    try {
      resetPasswordLoading = true
      let formData = {
        email: state?.state?.email ? state?.state?.email : email,
        password: password,
        c_password: confirmPassword,
        // role: "customer",
      };
      const resetPasswordPayload = await requestHandler("reset_password", "post", formData);
      if (resetPasswordPayload && resetPasswordPayload.status === 200) {
        setLoading(false)
        addNotification({
          title: 'Success',
          message: resetPasswordPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
        navigate("/sign-in");
      }
      else {
        addNotification({
          title: 'Error',
          message: resetPasswordPayload?.data?.message[0]?.msg,
          type: 'danger'
        });
      }
    }
    catch (e) {
      setLoading(false)
      resetPasswordLoading = false
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  return (
    <Login
      content="Don’t have an account?"
      linkText="Sign up for free"
      linkUrl="/sign-up"
    >
      <Logo />
      {/* form as per the active index */}
      {acitveIndex === 0 && <Form
        resetPasswordOtpGenerateHandler={resetPasswordOtpGenerateHandler}
        resetPasswordOtpLoading={resetPasswordOtpLoading}
      />}
      {acitveIndex === 1 && <Verification
        email={email}
        resetPasswordOtpGenerateHandler={resetPasswordOtpGenerateHandler}
        resetPasswordOtpLoading={resetPasswordOtpLoading}
        resetPasswordLoading={resetPasswordLoading}
        resetPasswordHandler={resetPasswordHandler}
        varifyOtp={varifyOtp}
        state={state}
      />}
      {acitveIndex === 2 && <NewPassword
        email={email}
        resetPasswordOtpGenerateHandler={resetPasswordOtpGenerateHandler}
        resetPasswordOtpLoading={resetPasswordOtpLoading}
        resetPasswordLoading={resetPasswordLoading}
        resetPasswordHandler={resetPasswordHandler}
      />}
      {loading && <LoaderScreen />}
    </Login>
  );
};

export default ForgotPassword;
